// * finds the image url given the id and the whole poweredImages object and Multilocation locationIndex

export default (poweredImageId, poweredImageObjects, locationIndex) => {
  // * uses the arrayOfImageIds in to find the index position of the image
  // * faster algothrim method to find by index than loops through all the image objects looking for a match.
  const poweredImagesIndex = poweredImageObjects.arrayOfImageIds?.[
    locationIndex
  ]?.indexOf(poweredImageId);

  const imageUrl =
    poweredImageObjects?.imageObjects?.[locationIndex]?.[poweredImagesIndex]
      ?.imageUrl;

  const notFoundImage =
    "https://res.cloudinary.com/gonation/image/upload/v1614006737/sites/general/404image.png";

  // * if the powered image url is present/found use it, else use powered images not found image
  return imageUrl ? imageUrl : notFoundImage;
};
