export default (navList, navLinksToRemove) => {
  return navList.filter(navLink => {
    return !navLinksToRemove
      .map(
        navLinksToRemove =>
          navLink?.ctaName.trim().toLowerCase() ===
          navLinksToRemove.trim().toLowerCase()
      )
      .includes(true)
  })
}

// navLinksToRemove comes from hideNavLinks in the component options

// I.e filterNavLinks(combinedNavLinks, hideNavLinks[locationIndex])

// Used on the NavigationSelector Page to Filter out any navlinks you want to remove

// i.e navLinkStructure = {ctaName: "about us", ctaLink: "about-us", order: 1.5}
// i.e navList = [ navLinkStructure , navLinkStructure , navLinkStructure ]
// i.e navLinksToRemove = ['about-us',"catering"]

// first loops through the navList with filter remove any of the navlinks that
// match anything in the menuSectionsToRemove to remove
// it is not case senstive
// navLinksToRemove comes from (hideNavLinks:[[]]) in the component options
