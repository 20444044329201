import React, { useState, useEffect } from 'react'
import createNavLinksObjects from '../../../../helpers/createNavLinkObjects'
import filterNavLinks from '../../../../helpers/filterNavLinks'

export default function NavigationSelector(props) {
  // === destructing ===
  const { navLinks, configData, locationIndex } = props
  const { options, additionalNavLinks } = configData?.navigation
  const { hideNavLinks } = options
  // === State ===
  const [Version, setVersion] = useState(null) //version of the component is stored in state
  // dyanmically imports the right component to render depending on
  //  the version in the config provided

  // === Functions ===
  // this is the dyanmic component selector. choose the right nav based on teh config data coming through.
  const addComponent = async version => {
    await import(`./${version}/Navigation.js`)
      .then(component => setVersion(() => component.default)) // must set component as a function
      .catch(error => {
        console.error(`"${version}" not yet supported - ${error}`)
      })
  }

  // takes an array of link names and creates and array of navlink objects.
  // i.e {ctaName: About Us, ctaLink:'about-us', order: 0.5}
  const newNavLinks = createNavLinksObjects(navLinks, configData)

  // check if there is any custom navlinks and then grabs its own location nav links. these are statically types custom links fromt eh config file
  // i.e [{ctaName: 'Order Online', ctaLink:'www.ubereats.com', order: 0.5},{ctaName: 'Catering', ctaLink:'catering', order: 0.5}]
  const customNavLinks = () => {
    return additionalNavLinks.length > 0
      ? additionalNavLinks?.[locationIndex]
      : []
  }

  // this combines the standard navlinks with the additional custom nav links
  const combinedNavLinks = [...newNavLinks, ...customNavLinks()]

  // we then sort the nav links based on the order property
  combinedNavLinks.sort((a, b) => (a.order < b.order ? -1 : 1))

  // === Mounting ===
  useEffect(() => {
    addComponent(configData.navigation.version) // runs onces on load
    return () => {}
  }, [configData])

  return (
    <div>
      {Version ? (
        <Version
          links={
            // if hideNavLinks is true then filter out out the the link
            hideNavLinks
              ? filterNavLinks(combinedNavLinks, hideNavLinks[locationIndex])
              : combinedNavLinks
          }
          {...props}
        />
      ) : (
        ''
      )}
    </div>
  )
}
