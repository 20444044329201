import findPoweredImageURLById from '../helpers/findPoweredImageURLById'
import checkIfExternalLink from '../helpers/checkIfExternalLink'

export default (image, backgroundGradient, poweredImages, locationIndex) => {
  const gradient = backgroundGradient || backgroundGradient === '0' ? backgroundGradient : '0.2'

  return `linear-gradient( rgba(0,0,0,${gradient}),rgba(0,0,0,0${gradient}) ), url('${
    !checkIfExternalLink(image)
      ? findPoweredImageURLById(image, poweredImages, locationIndex)
      : image
  }')`
}
