import React from 'react'
import { Box, Heading } from 'theme-ui'
import Header from './Header/Header'
import FooterSelector from './Footer/FooterSelector'
import CTAWidgetSelector from './CTAWidget/CTAWidgetSelector'

export default function Layout(props) {
  const capitalize = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const businessName = props.businessData.name
  const pageName = capitalize(props.pageObjectKey.replace('-', ' '))
  return (
    <>
      <Header {...props} />
      {/* SEO headings are for SEO. the generator dyanmically renders the site. so seo robots cannot read it. so this is statically rendered so ronbots can read it. */}
      <Box sx={styles.seoHeadings}>
        <Heading as='h1'>
          {pageName} - {businessName}
        </Heading>
      </Box>
      {props.children}
      <CTAWidgetSelector {...props} />
      <FooterSelector {...props} />
    </>
  )
}

const styles = {
  seoHeadings: {
    position: 'fixed',
    top: '-5000px',
    maxHeight: '300px',
  },
}

// List of props here
// {
//   children,
//   configData,
//   businessData,
//   navLinks,
//   locationIndex,
//   city,
//   locations,
//   gonationID,
//   isMultipleLocationSite
// }
